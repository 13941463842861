import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from '../store/index.js'

import PcLayout from '../layout/pcLayout.vue'
import PcVolunteerLayout from '../layout/pcVolunteerLayout.vue'


const routes = [
  {
    path: '/',
    name: 'Index',
    component: PcLayout,
    redirect:'/open',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/index.vue'),
        meta: {
          title: '登录',
          keepAlive: false,
        }
      },
      {
        path: '/paySuccess',
        name: 'paySuccess',
        component: () => import('../views/exception/paySuccess.vue'),
        meta: {
          title: '支付成功',
          keepAlive: false,
        }
      },
      {
        path: '/payError',
        name: 'payError',
        component: () => import('../views/exception/payError.vue'),
        meta: {
          title: '支付失败',
          keepAlive: false,
        }
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue'),
        meta: {
          title: '首页',
          keepAlive: false,
        }
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('../views/aboutUs/index.vue'),
        meta: {
          title: '关于我们',
          keepAlive: false,
        }
      },

      // ---------- 实施项目 start ----------
      {
        path: '/implementationProject',
        name: 'implementationProject',
        component: () => import('../views/implementationProject/index.vue'),
        meta: {
          title: '实施项目',
          keepAlive: false,
        }
      },
      {
        path: '/implementationProject/detail/:id',
        name: 'implementationProjectDetail',
        component: () => import('../views/implementationProject/detail.vue'),
        meta: {
          title: '实施项目详情',
          keepAlive: false,
        }
      },

      // ---------- 实施项目 end ----------
      {
        path: '/ruralRevitalization',
        name: 'ruralRevitalization',
        component: () => import('../views/ruralRevitalization/index.vue'),
        meta: {
          title: '乡村振兴',
          keepAlive: false,
        }
      },
      {
        path: '/ruralRevitalization/detail',
        name: 'ruralRevitalizationDetail',
        component: () => import('../views/ruralRevitalization/detail.vue'),
        meta: {
          title: '乡村振兴详情',
          keepAlive: false,
        }
      },


      {
        path: '/youthPublicWelfare',
        name: 'youthPublicWelfare',
        component: () => import('../views/youthPublicWelfare/index.vue'),
        meta: {
          title: '青春公益',
          keepAlive: false,
        }
      },
      {
        path: '/volunteerService',
        name: 'volunteerService',
        component: () => import('../views/volunteerService/index.vue'),
        meta: {
          title: '志愿服务',
          keepAlive: false,
        }
      },
      {
        path: '/volunteerService/detail',
        name: 'volunteerServiceDetail',
        component: () => import('../views/volunteerService/detail.vue'),
        meta: {
          title: '志愿服务详情',
          keepAlive: false,
        }
      },


      {
        path: '/loveStudy',
        name: 'loveStudy',
        component: () => import('../views/loveStudy/index.vue'),
        meta: {
          title: '爱心助学',
          keepAlive: false,
        }
      },
      {
        path: '/loveStudy/detail/:id',
        name: 'loveStudyDetail',
        component: () => import('../views/loveStudy/detail.vue'),
        meta: {
          title: '爱心助学',
          keepAlive: false,
        }
      },
      
      {
        path: '/onlineSale',
        name: 'onlineSale',
        component: () => import('../views/onlineSale/index.vue'),
        meta: {
          title: '在线义卖',
          keepAlive: false,
        },
      },
      {
        path: '/onlineSale/detail/:id',
        name: 'onlineSaleDetail',
        component: () => import('../views/onlineSale/detail.vue'),
        meta: {
          title: '义卖详情',
          keepAlive: false,
        },
      },
      {
        path: '/onlineSale/order/:id',
        name: 'onlineSaleOrder',
        component: () => import('../views/onlineSale/order.vue'),
        meta: {
          title: '义卖下单',
          keepAlive: false,
        },
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../views/news/index.vue'),
        meta: {
          title: '新闻动态',
          keepAlive: false,
        }
      },
      {
        path: '/news/detail/:id',
        name: 'newsDetail',
        component: () => import('../views/news/detail.vue'),
        meta: {
          title: '新闻动态详情',
          keepAlive: false,
        }
      },

      {
        path: '/informationDisclosure',
        name: 'informationDisclosure',
        component: () => import('../views/informationDisclosure/index.vue'),
        meta: {
          title: '信息公开',
          keepAlive: false,
        }
      },
      {
        path: '/informationDisclosure/detail',
        name: 'informationDisclosureDetail',
        component: () => import('../views/informationDisclosure/detail.vue'),
        meta: {
          title: '信息公开详情',
          keepAlive: false,
        }
      },
      
      {
        path: '/partner',
        name: 'partner',
        component: () => import('../views/partner/index.vue'),
        meta: {
          title: '合作伙伴',
          keepAlive: false,
        }
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('../views/contactUs/index.vue'),
        meta: {
          title: '联系我们',
          keepAlive: false,
        }
      },

      {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('../views/userCenter/index.vue'),
        meta: {
          title: '个人中心',
          keepAlive: false,
        }
      },
      {
        path: '/userCenter/baseInfoForm',
        name: 'userCenter/baseInfoForm',
        component: () => import('../views/userCenter/baseInfoForm.vue'),
        meta: {
          title: '基本信息',
          keepAlive: false,
        }
      },

    ],
  },
  {
    path: '/volunteer',
    name: 'Volunteer',
    component: PcVolunteerLayout,
    redirect:'/volunteer/home',
    children: [
      {
        path:'home',
        name:'volunteer-home',
        component:()=>import('@/views/volunteer'),
        meta:{
          title:'志愿者'
        }
      },
      {
        path: 'aboutUs',
        name: 'volunteer-aboutUs',
        // component: () => import('../views/aboutUsVolunteer/index.vue'),
        component: () => import('../views/aboutUs/index.vue'),
        meta: {
          title: '关于我们',
          keepAlive: false,
        }
      },

      {
        path: 'news',
        name: 'volunteer-news',
        component: () => import('../views/volunteer/news/index.vue'),
        meta: {
          title: '志愿动态',
          keepAlive: false,
        }
      },
      {
        path: 'news/detail/:id',
        name: 'volunteer-newsDetail',
        component: () => import('../views/volunteer/news/detail.vue'),
        meta: {
          title: '志愿动态详情',
          keepAlive: false,
        }
      },
      {
        path: 'demeanour',
        name: 'volunteer-demeanour',
        component: () => import('../views/volunteer/demeanour/index.vue'),
        meta: {
          title: '志愿风采',
          keepAlive: false,
        }
      },
      {
        path: 'demeanour/detail/:id',
        name: 'volunteer-demeanourDetail',
        component: () => import('../views/volunteer/demeanour/detail.vue'),
        meta: {
          title: '志愿风采详情',
          keepAlive: false,
        }
      },
      {
        path: 'informationDisclosure',
        name: 'volunteer-informationDisclosure',
        component: () => import('../views/volunteer/informationDisclosure/index.vue'),
        meta: {
          title: '政策文件',
          keepAlive: false,
        }
      },
      {
        path: 'informationDisclosure/detail',
        name: 'volunteer-informationDisclosureDetail',
        component: () => import('../views/volunteer/informationDisclosure/detail.vue'),
        meta: {
          title: '政策文件详情',
          keepAlive: false,
        }
      },

      {
        path: 'volunteerService',
        name: 'volunteer-volunteerService',
        component: () => import('../views/volunteerService/index.vue'),
        meta: {
          title: '志愿服务',
          keepAlive: false,
        }
      },
      {
        path: 'volunteerService/detail/:id',
        name: 'volunteer-volunteervolunteerServiceDetail',
        component: () => import('../views/volunteerService/detail.vue'),
        meta: {
          title: '志愿服务详情',
          keepAlive: false,
        }
      },
    ]
  },
  {
    path: '/open',
    name: 'Open',
    component:()=>import('@/views/open'),
    meta:{
      title:'上海市青少年发展基金会'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


let needloginRouters = [
  'userCenter',
]

router.beforeEach(async (to,from,next)=>{
  if (to.query.code) {
    await store.dispatch('wxLoginByCode', {
      code: to.query.code,
      redirect: from.fullPath
    }).then(res => {})
    next();
    return;
  }


  if (to.name.indexOf('volunteer-') > -1) {
    document.title = '上海市希望工程和青年志愿者指导中心（上海市爱心助学事务中心）'
    // 设置图标
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/logo2.ico';
    document.getElementsByTagName('head')[0].appendChild(link);

  } else {
    document.title = '上海市青少年发展基金会'
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/logo1.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  let needloginRouter = needloginRouters.find(x => x === to.name);
  let token = localStorage.getItem('token');

  if (to.name === 'login' || (needloginRouter && !token)) {
    store.dispatch('wxLogin', {}).then(res => {})
    return;
  }

  
  
  // store.dispatch('scanLogin')

  next()
})

export default router
